import { useState, useEffect, useRef, useMemo, useContext } from "react";

import JobSummary from "../Components/profile/Jobsummary";
import Skills from "../Components/profile/Skills";
import Specialty from "../Components/profile/Specialty";
import WorkHistory from "../Components/profile/WorkHistory";
import ProjectHistory from "../Components/profile/ProjectHistory";
import { ContextData } from "../Store/Context";

const Profile = () => {
  const modalRef = useRef(null);
  const { setEditJobSummaryData, setAddProjecthistoryData, setEditProjecthistoryData } = useContext(ContextData)

  const [openJobSummary, setOpenJobSummary] = useState(false);
  const [openSelfPromotion, setOpenSelfPromotion] = useState(false);
  const [openEditSpeciality, setOpenEditSpeciality] = useState(false);
  const [openEditSkills, setOpenEditSkills] = useState(false);
  const [openEditWorkHistory, setOpenEditWorkHistory] = useState(false);
  const [openEditProjectHistory, setOpenEditProjectHistory] = useState(false);
  const [openAddBiomodal, setOpenAddBiomodal] = useState(false);
  const [openWorkHistoryBio, setOpenWorkHistoryBio] = useState(false);
  const [modalOpenWork, setModalOpenWork] = useState(false);
  const [modalOpenProject, setModalOpenProject] = useState(false);

  const modalStates = useMemo(
    () => [
      { state: modalOpenWork, setState: setModalOpenWork },
      { state: modalOpenProject, setState: setModalOpenProject },
      { state: openJobSummary, setState: setOpenJobSummary },
      { state: openSelfPromotion, setState: setOpenSelfPromotion },
      { state: openEditSpeciality, setState: setOpenEditSpeciality },
      { state: openEditSkills, setState: setOpenEditSkills },
      { state: openEditWorkHistory, setState: setOpenEditWorkHistory },
      { state: openEditProjectHistory, setState: setOpenEditProjectHistory },
      { state: openAddBiomodal, setState: setOpenAddBiomodal },
      { state: openWorkHistoryBio, setState: setOpenWorkHistoryBio },
    ],
    [
      modalOpenWork,
      modalOpenProject,
      openJobSummary,
      openSelfPromotion,
      openEditSpeciality,
      openEditSkills,
      openEditWorkHistory,
      openEditProjectHistory,
      openAddBiomodal,
      openWorkHistoryBio,
    ]
  );

  useEffect(() => {
    if (
      modalOpenWork ||
      modalOpenProject ||
      openJobSummary ||
      openSelfPromotion ||
      openEditSpeciality ||
      openEditSkills ||
      openEditWorkHistory ||
      openEditProjectHistory ||
      openAddBiomodal ||
      openWorkHistoryBio
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [
    modalOpenProject,
    modalOpenWork,
    modalStates,
    openAddBiomodal,
    openEditProjectHistory,
    openEditSkills,
    openEditSpeciality,
    openEditWorkHistory,
    openJobSummary,
    openSelfPromotion,
    openWorkHistoryBio,
  ]);

  useEffect(() => {
    const keyPress = (e) => {
      if (e.key === "Escape") {
        modalStates.forEach(({ setState }) => setState(false));
      }
    };
    document.addEventListener("keydown", keyPress);

    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [modalStates]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        modalStates.forEach(({ setState }) => setState(false));
        setEditJobSummaryData("")
        setAddProjecthistoryData("")
        setEditProjecthistoryData("")
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalStates]);

  return (
    <div className="mt-[16px]">
      <JobSummary
        openJobSummary={openJobSummary}
        setOpenJobSummary={setOpenJobSummary}
        refModal={modalRef}
      />
      {/* <SelfPromotion
        openSelfPromotion={openSelfPromotion}
        setOpenSelfPromotion={setOpenSelfPromotion}
        refModal={modalRef}
      /> */}
      <Specialty
        openEditSpeciality={openEditSpeciality}
        setOpenEditSpeciality={setOpenEditSpeciality}
        refModal={modalRef}
      />
      <Skills
        openEditSkills={openEditSkills}
        setOpenEditSkills={setOpenEditSkills}
        refModal={modalRef}
      />
      <WorkHistory
        modalOpen={modalOpenWork}
        setModalOpen={setModalOpenWork}
        openEditWorkHistory={openEditWorkHistory}
        setOpenEditWorkHistory={setOpenEditWorkHistory}
        openWorkHistoryBio={openWorkHistoryBio}
        setOpenWorkHistoryBio={setOpenWorkHistoryBio}
        refModal={modalRef}
      />
      <ProjectHistory
        modalOpen={modalOpenProject}
        setModalOpen={setModalOpenProject}
        openEditProjectHistory={openEditProjectHistory}
        setOpenEditProjectHistory={setOpenEditProjectHistory}
        openAddBiomodal={openAddBiomodal}
        setOpenAddBiomodal={setOpenAddBiomodal}
        refModal={modalRef}
      />
    </div>
  );
};
export default Profile;
