import React, { useState } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";
import Loader from "../../Components/buttons/Loader";
import PassHideShow from "../../Components/buttons/PassHideShow";
// import InputFiledStroke from "../../Components/buttons/InputFiledStroke";
import { Forgot_ResetPasswordSchema } from "../../Components/validation/FormikValidation";
import { useResetPasswordByForgattingMutation } from "../../Store/slice/ForgetPassword";
import ForgetSuccess from "./ForgetSuccess";
import PasswordLinkExpired from "./PasswordLinkExpired";

function ResetPasswordPage() {
  const { forgotToken } = useParams("forgotToken");
  const [resetPassword, { isLoading }] = useResetPasswordByForgattingMutation();
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [resetResult, setResetResult] = useState("");

  return resetResult === "" ? (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center w-[150px] sm:w-[250px] md:w-[270px] lg:w-[377px]">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center"
          />
          <div className="text-center my-[14px] md:my-[18px] lg:my-[40px]">
            <h1 className="leading-[60px] text-[22px] md:text-[28px] lg:text-[34px] font-bold">
              パスワード再設定
            </h1>
            <p className="w-full mt-[10px] md:mt-[12px] lg:mt-[16px] text-[6px] md:text-[10px] lg:text-[14px] font-normal leading-[22px] text-[#212121]">
              新しいパスワードを入力し、
              <br />
              「パスワードを再設定する」をクリックしてください。
            </p>
          </div>
        </div>
        <div>
          <Formik
            initialValues={{
              new_password: "",
              confirm_password: "",
            }}
            validationSchema={Forgot_ResetPasswordSchema}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
              const data = {
                password: values?.new_password,
              };
              const response = await resetPassword({ data, forgotToken });

              if (response?.error) {
                toast.error(
                  response?.error?.data?.errors[0] || "何か問題が発生しました"
                );
                setResetResult(false);
              }

              if (response?.data?.success === true) {
                toast.success(
                  response?.data?.message ||
                    "パスワードが正常に変更されました。"
                );
                setSubmitting(false);
                setResetResult(true);
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="max-h-[250px] lg:max-h-[unset] overflow-y-auto overflow-hidden"
              >
                <div className="mb-[20px]">
                  <label
                    htmlFor="new_password"
                    className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[21px] text-[#212121]"
                  >
                    新しいパスワード
                  </label>
                  <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                    <input
                      name="new_password"
                      type={newPass ? "text" : "password"}
                      placeholder="新しいパスワードを入力してください。"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className="custom-input w-full p-[12px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent"
                    />
                    <div className="custom-border"></div>
                    <span
                      className="absolute top-[33%] right-[4%]"
                      onClick={() => setNewPass(!newPass)}
                    >
                      <PassHideShow ShowHide={newPass} />
                    </span>
                  </div>
                  <p className="text-[10px] leading-[22px] mt-[5px] text-[#868686] font-medium">
                    ※半角英数字の組み合わせ（8文字以上15文字以下）
                  </p>
                  {errors.new_password && touched.new_password && (
                    <p className="text-red-600 text-[12px]">
                      *{errors.new_password}
                    </p>
                  )}
                </div>
                <div className="mb-[16px]">
                  <label
                    htmlFor="confirm_password"
                    className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[21px] text-[#212121]"
                  >
                    新しいパスワード（確認）
                  </label>
                  <div className="relative flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center">
                    <input
                      name="confirm_password"
                      type={confirmPass ? "text" : "password"}
                      placeholder="新しいパスワード（確認）を入力してください。"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirm_password}
                      className="border border-[#CED0D8] w-full p-[12px] pr-[45px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent"
                    />
                    <div className="custom-border-pass"></div>
                    <span
                      className="absolute top-[33%] right-[4%] z-[99999]"
                      onClick={() => setConfirmPass(!confirmPass)}
                    >
                      <PassHideShow ShowHide={confirmPass} />
                    </span>
                  </div>
                  <p className="text-[10px] leading-[22px] mt-[5px] text-[#868686] font-medium">
                    ※半角英数字の組み合わせ（8文字以上15文字以下）
                  </p>
                  {errors.confirm_password && touched.confirm_password && (
                    <p className="text-red-600 text-[12px]">
                      *{errors.confirm_password}
                    </p>
                  )}
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    disabled={isSubmitting || isLoading}
                    className={`w-[142px] sm:w-[242px] md:w-[262px] lg:w-[387px] h-[60px] leading-6 font-medium ${
                      isLoading || isSubmitting
                        ? "bg-neutral-500"
                        : "bg-custom-gradient"
                    } text-[#fff]`}
                  >
                    {isLoading || isSubmitting ? (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    ) : (
                      "パスワードを再設定する"
                    )}
                  </button>
                  <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : resetResult === true ? (
    <ForgetSuccess />
  ) : (
    <PasswordLinkExpired />
  );
}

export default ResetPasswordPage;
