import React from "react";

// import { Formik } from "formik";

// import Data from "../../JSON/data.json";
import logo from "../../Assets/Images/logo.png";
// import { ForgotPasswordSchema } from "../../Components/validation/FormikValidation";
// import InputFiledStroke from "../../Components/buttons/InputFiledStroke";

function PasswordLinkSent() {

  return (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center w-[169px] sm:w-[269px] md:w-[289px] lg:w-[406px]">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center"
          />
          <div className="text-center my-[14px] md:my-[18px] lg:my-[40px]">
            <h1 className="leading-[60px] text-[22px] md:text-[28px] lg:text-[34px] font-bold">
              メールを送信しました
            </h1>
            <p className="w-full mt-[10px] md:mt-[12px] lg:mt-[16px] text-[6px] md:text-[10px] lg:text-[14px] font-normal leading-[22px] text-[#212121]">
              パスワード再設定用メールを送信しました。
              <br />
              メールに記載されたリンクより、再設定を行ってください。
            </p>
          </div>
        </div>
        {/* <div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ForgotPasswordSchema}
            enableReinitialize={true}
            onSubmit={async (values, { setErrors, resetForm }) => {}}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-[20px] lg:mb-[40px]">
                  <label
                    htmlFor="email"
                    className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[21px] text-[#212121]"
                  >
                    メールアドレス
                  </label>
                  <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center">
                    <input
                      name="email"
                      type="email"
                      placeholder={Data?.email_placeholder}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className="w-full h-[49px] py-[6px] md:py-[10] lg:py-[16px] px-[10px] md:px-[14px] lg:px-[20px] border border-[#FF6F00] border-r-0 focus:outline-none focus:bg-[#fff] "
                    />
                    <InputFiledStroke />
                  </div>
                  {errors.email && touched.email && (
                    <p className="text-red-600 text-[12px]">*{errors.email}</p>
                  )}
                </div>
                <div className="devider mt-[16px] lg:mt-[40px] mb-[16px] lg:mb-[40px] h-[1px] md:w-[280px] lg:w-[405px] bg-[#D6DEE2]"></div>
                <div className="flex flex-col justify-center items-center gap-7 border-[#D6DEE2] w-[169px] sm:w-[269px] md:w-[289px] lg:w-[406px]">
                  <p className="text-xs">
                    メールに記載された登録URLの有効期限は24時間です。有効期限が切れた場合は再度メールアドレスの入力を行ってください。
                  </p>
                  <Link className="text-xs" to="/forgot-resetpassword">
                    迷惑メールフォルダに振り分けられていることもありますのでご確認ください。
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div> */}
        <div className="devider mt-[16px] lg:mt-[40px] mb-[16px] lg:mb-[40px] h-[1px] md:w-[280px] lg:w-[405px] bg-[#D6DEE2]"></div>
        <div className="flex flex-col justify-center items-center gap-7 border-[#D6DEE2] w-[169px] sm:w-[269px] md:w-[289px] lg:w-[406px]">
          <p className="text-xs">
            メールに記載された登録URLの有効期限は24時間です。有効期限が切れた場合は再度メールアドレスの入力を行ってください。
          </p>
          <p className="text-xs">
            迷惑メールフォルダに振り分けられていることもありますのでご確認ください。
          </p>
        </div>
      </div>
    </div>
  );
}

export default PasswordLinkSent;
