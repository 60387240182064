import { Link } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";

const UrlExpired = () => {

  return (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center"
          />
          <h1 className="leading-[150%] text-[16px] md:text-[22px] lg:text-[34px] font-bold mt-[14px] md:mt-[18px] lg:mt-[18px] text-center">
            本人確認用URLの <br /> 有効期限が切れています
          </h1>
          <div className="mt-[40px] text-center text-[12px] lg:text-[16px]">
            <p>新規会員登録の本人確認用URLの有効期限が切れています。</p>
            <p>再度、新規会員登録をお願いいたします。</p>
          </div>
          <div className="flex flex-col justify-center items-center mt-[16px] lg:mt-[40px]">
            <Link className="flex" to="/signup">
              <button
                type="button"
                className={`w-[159px] sm:w-[259px] md:w-[279px] lg:w-[396px] h-[60px] leading-6 font-medium bg-custom-gradient text-[#fff]`}
              >
                再度新規会員登録をする
              </button>
              <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UrlExpired;
