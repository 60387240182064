import { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";

import PlusIcon from "../../Assets/icons/plusIcon.svg";
import OpenChev from "../../Assets/icons/openChev.svg";
import noteIcon from "../../Assets/icons/note.svg";
import DeleteButton from "../buttons/DeleteButton";
import EditButton from "../buttons/EditButton";
import EditProjectHistory from "../modals/EditProjectHistory";
import AddProjectHistory from "../modals/AddProjectHistory";
import Deletemodal from "../modals/Deletemodal";
import Data from "../../JSON/data.json";
import { ContextData } from "../../Store/Context";
import { useDeleteProjectHistoryMutation } from "../../Store/slice/ProjectSlice";
import AIDraft from "../modals/AIDraft";
import AIDraftProgress from "../modals/AIDraftProgress";
import AIDraftSuccess from "../modals/AIDraftSuccess";
// import { editProfileSchema } from "../validation/FormikValidation";

function ProjectHistory({
  modalOpen,
  setModalOpen,
  openEditProjectHistory,
  setOpenEditProjectHistory,
  openAddBiomodal,
  setOpenAddBiomodal,
  refModal,
}) {
  const [aiBaseText, setAiBaseText] = useState("")
  const { userDetail, setUserDetails, aiCall, setAiCall } = useContext(ContextData);
  // const {  } = useContext(ContextData)
  const [deleteProjectHistory, { isLoading }] =
    useDeleteProjectHistoryMutation();

  const [projectHistory, setProjectHistory] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [id, setId] = useState([]);
  const [openAIModal, setOpenAIModal] = useState(false)
  const [openAIModalInProgress, setOpenAIModalInProgress] = useState(false)
  const [openAIModalSucess, setOpenAIModalSucess] = useState(false)
  // const [generatedText, setGeneratedText] = useState(Data.sample_generated_text)
  const [userInput, setUserInput] = useState('')
  const [selectedType,setSelectedType] = useState(1) // 1 => project details, 2 => achievements details

  useEffect(() => {
    setProjectHistory(userDetail?.details?.project_histories);
  }, [userDetail]);

  // if (!openAddBiomodal) {
  //   setAddProjecthistoryData("")
  // }
  // if (openEditProjectHistory) {
  //   setAiCall("editProjecthistory")
  // }

  const handleDelete = async () => {
    const response = await deleteProjectHistory(selectedId);
    if (response?.error?.data?.errors) {
      toast.error(response?.error?.data?.errors[0] || "Something went wrong!");
    }
    if (response?.data?.success === true) {
      const findIndex = projectHistory.findIndex((a) => a.id === selectedId);
      const updatedProjectHistory = [
        ...projectHistory.slice(0, findIndex),
        ...projectHistory.slice(findIndex + 1),
      ];
      setProjectHistory(updatedProjectHistory);
      setUserDetails({
        ...userDetail,
        details: {
          ...userDetail?.details,
          project_histories: updatedProjectHistory,
        },
      });
      toast.success(response?.data?.message);
    }
    setModalOpen(false);
  };

  const handleOpen = (projectId) => {
    if (projectId === id?.find((item) => item === projectId)) {
      setId(id?.filter((item) => item !== projectId));
    } else {
      setId([...id, projectId]);
    }
  };

  return (
    <section
      className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px] w-full"
      id="section5"
    >
      <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
        <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px] pb-[8px]">
          プロジェクト経歴
        </h1>
      </div>
      <div>
        {projectHistory?.length ? (
          projectHistory?.map((items, index) => {
            const skillData = items?.related_skills?.map((skill) => {
              const key = skill.split(":")[0].replace("{", "").trim();
              const value = skill
                .split(":")[1]
                .replace("}", "")
                .replace("[", "")
                .replace("]", "")
                .split(",");
              return { [key]: value };
            });
            return (
              <div
                key={index}
                className={`flex gap-[8px] ${
                  items !== projectHistory && "mt-[16px]"
                }`}
              >
                <div className="flex flex-col justify-between">
                  <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                    {items?.start_date}
                  </h1>
                  <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                    {items?.end_date}
                  </h1>
                </div>
                <div className="time-line my-[10px]"></div>
                <div className="flex flex-col gap-[4px] my-[11px] w-full">
                  <div>
                    <div className="flex justify-between">
                      <h1 className="text-[12px] lg:text-[14px] font-bold leading-[22px] flex items-center gap-[4px]">
                        <img
                          src={noteIcon}
                          alt="noteIcon"
                          className="h-[15px] w-[14px]"
                        />
                        <span>{items?.project_name}</span>
                      </h1>
                      <div className="flex gap-3">
                        <EditButton
                          onClick={() => {
                            setSelectedId(items?.id);
                            setOpenEditProjectHistory(!openEditProjectHistory);
                            setAiCall("editProjecthistory");
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setModalOpen(true);
                            setSelectedId(items?.id);
                          }}
                        />
                        {modalOpen && (
                          <Deletemodal
                            heading={"履歴を削除"}
                            text="この履歴を削除してもよろしいですか ?"
                            yes="削除"
                            no="キャンセル"
                            setModalOpen={setModalOpen}
                            handleDelete={() => handleDelete()}
                            refModal={refModal}
                            isLoading={isLoading}
                          />
                        )}
                      </div>
                    </div>
                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                      <img
                        src="/Assets/icons/userIcon.svg"
                        alt="companyIcon"
                        className="h-[15px] w-[14px]"
                      />
                      <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                        {items?.company}
                      </span>
                    </h1>
                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                      <img
                        src="/Assets/icons/position.svg"
                        alt="roleIcon"
                        className="h-[15px] w-[14px]"
                      />
                      <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                        {items?.role}
                      </span>
                    </h1>
                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                      <img
                        src="/Assets/icons/Addressicon.svg"
                        alt="affiliationIcon"
                        className="h-[15px] w-[14px]"
                      />
                      <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                        {items?.affiliation}
                      </span>
                    </h1>
                  </div>

                  <div>
                    {id?.find((item) => item === items?.id) === items?.id && (
                      <div>
                        <div className="mt-[16px] w-full">
                          <div>
                            <div className="pr-[0] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px]">
                              <h1 className="text-[#878787]">
                                プロジェクト概要 :
                              </h1>
                            </div>
                            <div className="mt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                              <h1 className="text-[#212121] leading-[22px] break-all">
                                {items?.project_overview}
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div className="mt-[16px]">
                          <div>
                            <h1 className="text-[#878787] text-[12px] lg:text-[14px] font-bold">
                              担当業務 :
                            </h1>
                            {items?.responsibilities?.map((items, index) => {
                              return (
                                <h1
                                  key={index}
                                  className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all"
                                >
                                  <img
                                    src="/Assets/icons/pinicon.svg"
                                    alt="icon"
                                  />
                                  <span>{items}</span>
                                </h1>
                              );
                            })}
                          </div>
                        </div>
                        <div className="mt-[16px] w-full">
                          <div>
                            <div className="pr-[0] mb-[4px] pl-[0] flex items-center font-bold text-[12px] lg:text-[14px]">
                              <h1 className="text-[#878787]">
                                実績 / 取り組み :
                              </h1>
                            </div>
                            <div className="pr-[0] mb-[4px] pl-[0] flex items-center font-normal text-[12px] lg:text-[14px]">
                              <h1 className="text-[#212121] leading-[22px] break-all">
                                {items?.achievements_initiatives}
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div className="mt-[16px]">
                          <div>
                            <h1 className="text-[#878787] text-[14px] font-bold leading-[22px]">
                              関連スキル :
                            </h1>
                          </div>
                          {skillData?.map((skill, index) => {
                            return (
                              <div key={index}>
                                <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                                  {Object.keys(skill)}
                                </h1>
                                <div className="flex mt-[8px] gap-[8px] flex-wrap">
                                  {Object.values(skill)[0].map(
                                    (relatedSkill, idx) => (
                                      <div
                                        key={idx}
                                        className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer"
                                      >
                                        {relatedSkill}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <button
                      type="button"
                      className="mt-[16px] flex items-center gap-[2px]"
                      onClick={() => {
                        handleOpen(items?.id);
                      }}
                    >
                      <p className="text-[12px] font-bold text-[#0956FF]">
                        {Data?.view_details_button}
                      </p>
                      <span>
                        {id?.find((item) => item === items?.id) ===
                        items?.id ? (
                          <img
                            src={OpenChev}
                            alt="cheav"
                            className="transform rotate-[180deg]"
                          />
                        ) : (
                          <img src={OpenChev} alt="cheav" />
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="font-bold text-center">データなし</div>
        )}
        <div className="mt-[24px] flex justify-center ">
          <button
            onClick={() => {
              setOpenAddBiomodal(!openAddBiomodal);
              setAiCall("addProjecthistory");
            }}
            type="button"
            className="flex bg-gradient-to-r from-[#0956FF] to-[#5389FF] py-[11px] w-[300px] h-[40px] justify-center gap-[4px] rounded-[4px]"
          >
            <img src={PlusIcon} alt="PlusIcon" />
            <p className="text-white text-[12px] font-bold leading-[18px] ml-[4px]">
              {Data?.add_bio_button}
            </p>
          </button>
        </div>
      </div>
      {openEditProjectHistory && (
        <EditProjectHistory
          id={selectedId}
          setOpenEditProjectHistory={setOpenEditProjectHistory}
          setProjectHistory={setProjectHistory}
          projectHistory={projectHistory}
          refModal={refModal}
          setOpenAIModal={setOpenAIModal}
          setSelectedType={setSelectedType}
        />
      )}
      {openAddBiomodal && (
        <AddProjectHistory
          setOpenAddBiomodal={setOpenAddBiomodal}
          setProjectHistory={setProjectHistory}
          projectHistory={projectHistory}
          refModal={refModal}
          setOpenAIModal={setOpenAIModal}
          setSelectedType={setSelectedType}
        />
      )}
      {openAIModal && (
        <AIDraft
          refModal={refModal}
          setOpenAIModal={setOpenAIModal}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
          setUserInput={setUserInput}
          setAiBaseText={setAiBaseText}
          setOpenAIModalSucess={setOpenAIModalSucess}
          aiCall={aiCall}
          setOpenAddBiomodal={setOpenAddBiomodal}
          setOpenEditProjectHistory={setOpenEditProjectHistory}
          header={
            selectedType === 1
              ? Data.ai_message_project
              : Data.ai_message_achievements
          }
          sampleInput={
            selectedType === 1
              ? Data.ai_sample_header_project
              : Data.ai_sample_header
          }
          sampleInputData={
            selectedType === 1
              ? Data.sample_text_ai_project
              : Data.sample_text_ai_achievements
          }
          selectedType={selectedType}
        />
      )}
      {openAIModalInProgress && (
        <AIDraftProgress
          refModal={refModal}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
        />
      )}
      {openAIModalSucess && (
        <AIDraftSuccess
          refModal={refModal}
          setOpenAIModalSucess={setOpenAIModalSucess}
          generatedText={aiBaseText}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          setOpenAIModal={setOpenAIModal}
          setUserInput={setUserInput}
          aiCall={aiCall}
          setOpenAddBiomodal={setOpenAddBiomodal}
          setOpenEditProjectHistory={setOpenEditProjectHistory}
          userInput={userInput}
        />
      )}
    </section>
  );
}
export default ProjectHistory;
