import React from "react";
import RightMark from "../../Assets/Images/registration-rightmark.svg";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../Store/slice/AuthSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../buttons/Loader";

const Step4 = () => {
  const navigate = useNavigate();

  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();

  if (isstepsError) {
    if (isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(isstepsError?.data?.message || "Something went wrong!");
    }
  }

  const handleSubmit = async () => {
    const stepResponse = await registerUserDetail({
      steps_completed: "completion",
    });

    if (stepResponse?.error) {
      toast.success(stepResponse?.error?.data?.errors[0]);
    }

    if (stepResponse?.data?.success === true) {
      toast.success(
        stepResponse?.data?.message || "変更は正常に保存されました..!"
      );
      navigate("/profile");
    }
  };
  return (
    <div className="py-10 px-20 bg-white h-fit w-fit flex flex-col items-center">
      <div className="w-fit flex flex-col gap-4 items-center">
        <img src={RightMark} alt="" className="w-16 h-16" />
        <h1 className="text-[#0956FF] text-[24px] font-bold">
          職務経歴書のダウンロードができました！
        </h1>
        <h6 className="text-sm text-black font-normal">
          今までのステップで簡単に職務経歴書の作成から出力まで行えます。
        </h6>
      </div>
      <button
        onClick={() => handleSubmit()}
        disabled={isstepsLoading}
        className={`py-3 px-[121px] ${
          isstepsLoading
            ? "bg-neutral-500"
            : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
        } rounded-[4px] mt-10 font-bold text-white`}
      >
        {isstepsLoading ? <Loader /> : "ホームヘ"}
      </button>
    </div>
  );
};

export default Step4;
