import React from "react";
import Loader from "../../buttons/Loader";
import { Formik } from "formik";
import { AddWorkHistoryBioSchema } from "../../validation/FormikValidation";
import { useAddWorkHistoryMutation } from "../../../Store/slice/WorkSlice";
import { Sales_amount, emp_type, listed_type } from "../../comman/Options";
import { scrollToErrors } from "../../comman/ScrollToError";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../../Store/slice/AuthSlice";

const InnerStep4 = ({
  openStep,
  setOpenStep,
  setCurrentStep,
  userDetail,
  setUserDetails,
  setSubStep,
}) => {
  const CurrentYear = new Date().getFullYear();
  const CurrentMonth = new Date().getMonth() + 1;
  const [addWorkHistory, { error, isLoading }] = useAddWorkHistoryMutation();
  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();
  const work =
    userDetail?.details?.work_histories?.[
      userDetail?.details?.work_histories?.length - 1
    ];

  if (error || isstepsError) {
    if (error?.originalStatus || isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(
        error?.data?.message ||
          isstepsError?.data?.message ||
          "Something went wrong!"
      );
    }
  }

  const startdate = new Date(work?.start_date);
  const startYear = startdate?.getFullYear();
  const startMonth = startdate?.getMonth() + 1;

  const enddate = new Date(work?.end_date);
  const endYear = enddate?.getFullYear();
  const endMonth = enddate?.getMonth() + 1;

  return (
    <Formik
      initialValues={{
        currently_affiliated: work?.currently_affiliated || "",
        company_name: work?.company_name || "",
        position: work?.position || "",
        description: work?.description || "",
        start_year: startYear || "2010",
        start_month: startMonth || new Date().getMonth() + 1,
        end_year: endYear || new Date().getFullYear(),
        end_month: endMonth || new Date().getMonth() + 1,
        employment_type: work?.employment_type || "",
        amount_of_sales: work?.amount_of_sales || "",
        capital: work?.capital || "",
        listing_type: work?.listing_type || "",
        number_of_employees: work?.number_of_employees || "",
        business_details: work?.business_details || "",
      }}
      validationSchema={AddWorkHistoryBioSchema}
      enableReinitialize={true}
      innerRef={(ref) => {
        if (ref?.errors && ref?.isSubmitting === true) {
          scrollToErrors(ref?.errors);
        }
      }}
      onSubmit={async (values, { setErrors, resetForm }) => {
        if (values.start_year === values.end_year) {
          if (values.start_month > values.end_month) {
            setErrors({ end_month: "end month is not valid" });
            return;
          }
        }

        const start = new Date(
          values.start_year.toString() +
            "-" +
            values.start_month.toString() +
            "-" +
            "01"
        )
          .toLocaleDateString("ja-JP")
          .replaceAll("/", "-");
        const end = new Date(
          values.end_year.toString() +
            "-" +
            values.end_month.toString() +
            "-" +
            "30"
        )
          .toLocaleDateString("ja-JP")
          .replaceAll("/", "-");
        const response = await addWorkHistory({
          work_history: {
            ...values,
            start_date: start,
            end_date: end,
          },
        });

        if (response?.error) {
          toast.error(
            response?.error?.data?.errors[0]?.detail || "何か問題が発生しました"
          );
        }

        if (response?.data?.success === true) {
          const stepResponse = await registerUserDetail({ steps_completed: "work_history" });

          if (stepResponse?.error) {
            toast.success(response?.error?.data?.errors[0]);
          }

          if (stepResponse?.data?.success === true) {
            setUserDetails({
              ...userDetail,
              details: {
                ...userDetail?.details,
                work_histories: [
                  ...userDetail?.details?.work_histories,
                  { ...response?.data?.data },
                ],
              },
            });
            setOpenStep(5);
            setSubStep(5);
            setCurrentStep(2);

            toast.success(
              response?.data?.message || "職歴が正常に追加されました。"
            );
            resetForm();
          }
        }
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="overflow-y-auto">
            <div>
              <h1
                autoFocus={openStep === 4}
                className="text-[14px] font-bold leading-[22px] text-[#878787]"
              >
                職務経歴(所属経歴)
              </h1>
              <div>
                <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                  <div className="w-[56px]">
                    <label htmlFor="company_name">社名</label>
                  </div>
                  <input
                    name="company_name"
                    type="text"
                    placeholder="社名が入ります"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_name}
                    className="flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                  />
                </div>
                {errors.company_name && touched.company_name && (
                  <p className="text-red-600 text-[12px] ml-[72px]">
                    *{errors.company_name}
                  </p>
                )}
              </div>
              <div className="mt-[16px]">
                <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                  <div className="w-[56px]">
                    <label htmlFor="position">役職</label>
                  </div>
                  <input
                    name="position"
                    type="text"
                    placeholder="役職が入ります"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.position}
                    className="flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                  />
                </div>
                {errors.position && touched.position && (
                  <p className="text-red-600 text-[12px] ml-[72px]">
                    *{errors.position}
                  </p>
                )}
              </div>
              <div className="mt-[16px]">
                <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                  <div className="w-[56px]">
                    <label htmlFor="description">業務内容</label>
                  </div>
                  <input
                    name="description"
                    type="text"
                    placeholder="業務内容が入ります"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    className="flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                  />
                </div>
                {errors.description && touched.description && (
                  <p className="text-red-600 text-[12px] ml-[72px]">
                    *{errors.description}
                  </p>
                )}
              </div>
              <div className="mt-[16px]">
                <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] ">
                  <div className="w-[56px]">
                    <label htmlFor="affiliation_period ">所属期間</label>
                  </div>
                  <div className="w-full font-normal py-[12px]">
                    <div className="flex items-center">
                      <input
                        name="start_year"
                        type="number"
                        min={1900}
                        max={new Date().getFullYear()}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.start_year}
                        className="w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px]"
                      />
                      <p className="px-[8px]">年</p>
                      <input
                        name="start_month"
                        type="number"
                        min={1}
                        max={12}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.start_month}
                        className="w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px]"
                      />
                      <div className="flex">
                        <p className="px-[8px]">月</p>
                        <p className="px-[8px]">〜</p>
                      </div>
                      <input
                        name="end_year"
                        type="number"
                        min={1900}
                        max={new Date().getFullYear()}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={values?.currently_affiliated}
                        value={values.end_year}
                        className={`w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px] ${
                          values?.currently_affiliated
                            ? "bg-gray-300"
                            : "bg-white"
                        }`}
                      />
                      <p className="px-[8px]">年</p>
                      <input
                        name="end_month"
                        type="number"
                        min={1}
                        max={
                          values?.end_year === new Date().getFullYear()
                            ? new Date().getMonth() + 1
                            : 12
                        }
                        onBlur={handleBlur}
                        disabled={values?.currently_affiliated}
                        onChange={handleChange}
                        value={values.end_month}
                        className={`w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px] ${
                          values?.currently_affiliated
                            ? "bg-gray-300"
                            : "bg-white"
                        }`}
                      />
                      <p className="px-[8px]">月</p>
                    </div>
                    <div className="flex justify-between ">
                      <div>
                        {errors.start_year && touched.start_year && (
                          <p className="text-red-600 text-[12px]">
                            *{errors.start_year}
                          </p>
                        )}
                        {errors.start_month && touched.start_month && (
                          <p className="text-red-600 text-[12px]">
                            *{errors.start_month}
                          </p>
                        )}
                      </div>
                      <div>
                        {errors.end_year && touched.end_year && (
                          <p className="text-red-600 text-[12px] ml-[-177px]">
                            *{errors.end_year}
                          </p>
                        )}
                        {errors.end_month && touched.end_month && (
                          <p className="text-red-600 text-[12px] ml-[-177px]">
                            *{errors.end_month}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <label className="cursor-pointer text-xs w-fit gap-3 flex justify-start items-center ml-[75px] registration">
                  <input
                    name="currently_affiliated"
                    type="checkbox"
                    checked={values?.currently_affiliated}
                    disabled={!values?.start_year || !values?.start_month}
                    className="h-[18px] w-[18px] cursor-pointer"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("currently_affiliated", e.target.checked);
                      if (e.target.checked === true) {
                        setFieldValue("end_year", CurrentYear);
                        setFieldValue("end_month", CurrentMonth);
                      }
                      if (e.target.checked === false) {
                        setFieldValue("end_year", values?.end_year);
                        setFieldValue("end_month", values?.end_month);
                      }
                    }}
                  />
                  現在も所属している
                </label>
              </div>
              <div className="mt-[16px]">
                <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                  <div className="w-[56px]">
                    <label htmlFor="employment_type">雇用形態</label>
                  </div>
                  <div className="w-[384px]">
                    <select
                      name="employment_type"
                      value={values.employment_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ display: "block" }}
                      className="w-[142px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                    >
                      <option value="">-- 雇用を選択 --</option>
                      {emp_type?.map((option, index) => {
                        return (
                          <option key={index} value={option?.value}>
                            {option?.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {errors.employment_type && touched.employment_type && (
                  <p className="text-red-600 text-[12px] ml-[72px]">
                    *{errors.employment_type}
                  </p>
                )}
              </div>
              <div className="mt-[24px]">
                <h1 className="text-[14px] font-bold leading-[22px] text-[#878787]">
                  会社概要
                </h1>
                <div>
                  <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                    <div className="w-[56px]">
                      <label htmlFor="amount_of_sales">売上高</label>
                    </div>
                    <div className="flex items-center">
                      <select
                        name="amount_of_sales"
                        value={values.amount_of_sales}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: "block" }}
                        className="w-[142px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                      >
                        <option value="">--売上高を選択 --</option>
                        {Sales_amount?.map((option, index) => {
                          return (
                            <option key={index} value={option?.value}>
                              {option?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {errors.amount_of_sales && touched.amount_of_sales && (
                    <p className="text-red-600 text-[12px] ml-[72px]">
                      *{errors.amount_of_sales}
                    </p>
                  )}
                </div>
                <div className="mt-[16px]">
                  <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                    <div className="w-[56px]">
                      <label htmlFor="listing_type">上場</label>
                    </div>
                    <div className="w-[384px]">
                      <select
                        name="listing_type"
                        value={values.listing_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: "block" }}
                        className="w-[142px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                      >
                        <option value="">-- リストを選択 --</option>
                        {listed_type?.map((option, index) => {
                          return (
                            <option key={index} value={option?.value}>
                              {option?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {errors.listing_type && touched.listing_type && (
                    <p className="text-red-600 text-[12px] ml-[72px]">
                      *{errors.listing_type}
                    </p>
                  )}
                </div>
                <div>
                  <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                    <div className="w-[56px]">
                      <label htmlFor="number_of_employees ">従業員数</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="number_of_employees"
                        type="number"
                        placeholder="2000"
                        min={0}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.number_of_employees}
                        className="w-[142px] h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                      />
                      <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                        人
                      </p>
                    </div>
                  </div>
                  {errors.number_of_employees &&
                    touched.number_of_employees && (
                      <p className="text-red-600 text-[12px] ml-[72px]">
                        *{errors.number_of_employees}
                      </p>
                    )}
                </div>
                <div>
                  <div className="flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                    <div className="w-[56px]">
                      <label htmlFor="business_details">事業内容</label>
                    </div>
                    <input
                      name="business_details"
                      type="text"
                      placeholder="事業内容が入ります事業内容が入ります"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.business_details}
                      className="flex-1 h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                    />
                  </div>
                  {errors.business_details && touched.business_details && (
                    <p className="text-red-600 text-[12px] ml-[72px]">
                      *{errors.business_details}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-[40px]">
              <div className="flex gap-[10px] items-center">
                <button
                  type="button"
                  className="py-[12px] px-[24px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                  onClick={() => {
                    setCurrentStep(2);
                    setOpenStep(3);
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="submit"
                  disabled={
                    Object.keys(errors)?.length || isLoading || isstepsLoading
                  }
                  className={`py-[12px] px-[24px] ${
                    Object.keys(errors)?.length || isLoading || isstepsLoading
                      ? "bg-neutral-500"
                      : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                  } rounded-[4px] text-xs font-bold text-[#fff]`}
                >
                  {isLoading || isstepsLoading ? (
                    <div className="flex justify-center">
                      <Loader />
                    </div>
                  ) : (
                    "保存する"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default InnerStep4;
