import React from "react";

import { Link } from "react-router-dom";

import Data from "../../JSON/data.json";
import Logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";

function ForgetSuccess() {

  return (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center w-[150px] sm:w-[250px] md:w-[270px] lg:w-[387px]">
          <img
            src={Logo}
            alt="Logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center"
          />
          <div className="text-center my-[14px] md:my-[18px] lg:my-[40px]">
            <h1 className="leading-[60px] text-[22px] md:text-[28px] lg:text-[34px] font-bold">
              パスワードを <br /> 再設定しました
            </h1>
            <p className="w-full mt-[10px] md:mt-[12px] lg:mt-[16px] text-[6px] md:text-[10px] lg:text-[14px] font-normal leading-[22px] text-[#212121]">
              パスワードの再設定が完了しました。
              <br />
              新しいパスワードで、再度ログインをお願いします。
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex justify-center mt-[16px]">
              <Link to="/">
                <div className="flex">
                  <button
                    type="submit"
                    className={`w-[150px] sm:w-[250px] md:w-[270px] lg:w-[387px] h-[60px] leading-6 font-medium bg-custom-gradient text-[#fff]`}
                  >
                    {Data?.log_in_button}
                  </button>
                  <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetSuccess;
