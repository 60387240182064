import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"; 

export const ForgotPasswordMail = createApi ({
    reducerPath: "ForgotPasswordMail",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api"
    }),
    endpoints: (builder)=>({
        ForgotPasswordMail: builder.mutation({
            query: (payload)=>{
                return{
                    url: "/v1/users/password/forgot",
                    method: "Post",
                    body: payload,
                }
            }
        }),
        ResetPasswordByForgatting: builder.mutation({
            query: (payload)=>{
                return{
                    url: "/v1/users/password/reset",
                    method: "Post",
                    body: payload?.data,
                    headers:{
                        token: payload?.forgotToken,
                    }
                }
            }
        })
    })
})

export const { useForgotPasswordMailMutation } = ForgotPasswordMail
export const { useResetPasswordByForgattingMutation } = ForgotPasswordMail