import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Data from "../../JSON/data.json";
import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";
import Loader from "../../Components/buttons/Loader";
// import InputFiledStroke from "../../Components/buttons/InputFiledStroke";
import { ForgotPasswordSchema } from "../../Components/validation/FormikValidation";
import { useForgotPasswordMailMutation } from "../../Store/slice/ForgetPassword";

const ForgotPassword = () => {
  const [sendMail, { isLoading }] = useForgotPasswordMailMutation();
  const navigate = useNavigate();

  return (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center w-[169px] sm:w-[269px] md:w-[289px] lg:w-[406px]">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center"
          />
          <div className="text-center my-[14px] md:my-[18px] lg:my-[40px]">
            <h1 className="leading-[60px] text-[22px] md:text-[28px] lg:text-[34px] font-bold">
              パスワード再設定
            </h1>
            <p className="w-full mt-[10px] md:mt-[12px] lg:mt-[16px] text-[6px] md:text-[10px] lg:text-[14px] font-normal leading-[22px] text-[#212121]">
              パスワード再設定のために、
              <br />
              ご登録されているメールアドレスを入力してください。
            </p>
          </div>
        </div>
        <div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ForgotPasswordSchema}
            enableReinitialize={true}
            onSubmit={async (values,{ setSubmitting, setErrors, resetForm }) => {
              setSubmitting(true);
              const response = await sendMail(values);

              if (response?.error) {
                toast.error(
                  response?.error?.data?.errors[0]?.detail ||
                    "何か問題が発生しました"
                );
              }

              if (response?.data?.success === true) {
                toast.success(
                  response?.data?.message ||
                    "あなたのメールアドレスにメールが正常に送信されました。"
                );
                navigate("/password-link-sent");
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-[20px] lg:mb-[40px] overflow-hidden">
                  <label
                    htmlFor="email"
                    className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[21px] text-[#212121]"
                  >
                    メールアドレス
                  </label>
                  <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                    <input
                      name="email"
                      type="email"
                      placeholder={Data?.email_placeholder}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className="custom-input w-full p-[12px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent"
                    />
                    <div className="custom-border"></div>
                  </div>
                  {errors.email && touched.email && (
                    <p className="text-red-600 text-[12px]">*{errors.email}</p>
                  )}
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    disabled={isLoading || isSubmitting}
                    className={`w-[150px] sm:w-[250px] md:w-[270px] lg:w-[387px] h-[60px] leading-6 font-medium ${
                      isSubmitting || isLoading
                        ? "bg-neutral-500"
                        : "bg-custom-gradient"
                    } text-[#fff]`}
                  >
                    {isSubmitting || isLoading ? (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    ) : (
                      "パスワード再設定依頼をする"
                    )}
                  </button>
                  <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                </div>
              </form>
            )}
          </Formik>
          <div className="w-[169px] sm:w-[269px] md:w-[289px] lg:w-[406px]">
            <Link to="/" className="flex justify-center mt-[16px]">
              <p className="font-bold text-[16px] text-[#212121] leading-[22px]">
                キャンセル
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
