import React from "react";
import Data from "../../JSON/data.json";
import CloseModalButton from "../buttons/CloseModalButton";
import { scrollToErrors } from "../comman/ScrollToError";
import { Formik } from "formik";
import { summarySchema } from "../validation/FormikValidation";
import { toast } from "react-toastify";
import { useAddAidraftMutation } from "../../Store/slice/AidraftSlice";
import { useLocation } from "react-router-dom";

const AIDraft = ({
  refModal,
  setOpenAIModal,
  setOpenAIModalInProgress,
  userInput,
  setUserInput,
  setAiBaseText,
  setOpenAIModalSucess,
  aiCall,
  setOpenAddBiomodal,
  setOpenEditProjectHistory,
  setOpenJobSummary,
  header,
  sampleInput,
  sampleInputData,
  selectedType,
}) => {
  const { pathname } = useLocation();
  const [addAidraft, { isLoading }] = useAddAidraftMutation();

  if (isLoading) {
    setOpenAIModalInProgress(true);
    setOpenAIModal(false);
  }
  // console.log("ai", aiCall);
  const closeAimodel = () => {
    switch (aiCall) {
      case "addProjecthistory":
        if (pathname !== "/registration-process") {
          setOpenAddBiomodal(true);
        }
        break;
      case "editProjecthistory":
        if (pathname !== "/registration-process") {
          setOpenEditProjectHistory(true);
        }
        break;
      case "editJobsummray":
        if (pathname !== "/registration-process") {
          setOpenJobSummary(true);
        }
        break;

      default:
        break;
    }
    setOpenAIModal(false);
    setUserInput("");
  };

  return (
    <div className="overlay">
      <div
        id=""
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className=" bg-[#fff] w-[500px] overflow-y-auto overflow-x-hidden"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-base tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  {Data?.ai_text}
                </h3>
                <CloseModalButton onClick={() => closeAimodel()} />
              </div>
            </div>
            <div className=" text-center mx-6 my-2">
              <h1 className="font-bold text-[#0956ff] mt-4">自身の経歴を箇条書きで書いてみよう！</h1>
              <h3 className="font-normal text-sm mt-4">{header}</h3>
            </div>
            <div className={`mx-[22px] my-5 p-6 bg-[#E6EEFF]`}>
              <h4 className={` text-sm font-bold text-[#0956FF]`}>
                {sampleInput}
              </h4>
              <ul className="text-sm font-normal leading-5 p-4 list-disc">
                {sampleInputData.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
            </div>
            <div className="mx-[22px] mb-[24px]">
              <Formik
                initialValues={{ job_summary: userInput }}
                validationSchema={summarySchema}
                enableReinitialize={true}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { resetForm }) => {
                  // console.log("values", values);
                  setUserInput(values?.job_summary);
                  const aiJob =
                    aiCall === "editJobsummray"
                      ? { job_summary: true }
                      : selectedType === 1
                        ? { project_overview: true }
                        : { achievements_initiatives: true };
                  const response = await addAidraft({
                    content: values?.job_summary,
                    ...aiJob,
                  });

                  if (response?.data) {
                    // const aiResult = response?.data?.content.split("•")
                    setAiBaseText(response?.data?.content);
                    setOpenAIModalSucess(true);
                  } else {
                    setOpenAIModalInProgress(false);
                    setOpenAIModal(true);
                    if (response?.error) {
                      toast.error(
                        response?.error?.data?.errors[0]?.detail ||
                        "何か問題が発生しました"
                      );
                    }
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="h-[290px] lg:h-[300px]">
                        <div className="w-full">
                          <textarea
                            name="job_summary"
                            cols="51"
                            rows="9"
                            className="text-[14px] font-normal leading-[22px] py-[8px] px-[10px] w-[inherit] border-2 border-[#CED0D8]-100 outline-none"
                            value={values?.job_summary}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.job_summary && touched.job_summary && (
                            <p className="text-red-600 text-[12px] ">
                              *{errors.job_summary}
                            </p>
                          )}
                        </div>
                        <div className="mt-7">
                          <div className="flex gap-[10px] items-center">
                            <button
                              type="submit"
                              disabled={isLoading}
                              // onClick={() => {
                              //   setOpenAIModalInProgress(true)
                              //   setOpenAIModal(false)
                              // }}
                              className={`w-full h-[40px] bg-gradient-to-r from-[#0956FF] to-[#5389FF]
                              rounded-[4px] text-xs font-bold text-[#fff]`}
                            >
                              {"ドラフト作成"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIDraft;
