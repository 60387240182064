import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Auth = createApi({
  reducerPath: "Auth",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  tagTypes: ["reFetch"],
  endpoints: (builder) => ({
    LoginUser: builder.mutation({
      query: (payload) => {
        return {
          url: "/v1/users/sessions",
          method: "POST",
          body: payload,
        };
      },
    }),
    RegisterUSer: builder.mutation({
      query: (payload) => ({
        url: "/v1/users/registration",
        method: "POST",
        body: payload,
      }),
    }),
    ForgotPassword: builder.mutation({
      query: (payload) => ({
        url: "/v1/users/password/forgot",
        method: "POST",
        body: payload,
      }),
    }),
    ResetPassword: builder.mutation({
      query: (payload) => ({
        url: "/v1/users/update_password",
        method: "PUT",
        body: payload,
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }),
    }),
    GetUserProfile: builder.query({
      query: () => ({
        url: "/v1/users/account_profile",
        method: "Get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["reFetch"],
    }),
    UpdateUser: builder.mutation({
      query: (updatedData) => {
        return {
          url: "/v1/users/update_profile",
          method: "Put",
          body: updatedData,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
      invalidatesTags: ["reFetch"],
    }),
    GetUserDetails: builder.query({
      query: () => ({
        url: "/v1/details",
        method: "Get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    UpdateUserDetails: builder.mutation({
      query: (payload) => ({
        url: "/v1/details",
        method: "Put",
        body: payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    RegisterAuthentication: builder.mutation({
      query: (payload) => ({
        url: "/v1/users/registration/email_confirmation",
        method: "Post",
        headers: {
          token: payload,
        },
      }),
    }),
    ResendRegistreVerfication: builder.mutation({
      query: (payload) => ({
        url: "/v1/users/send_email_verification_mail",
        method: "Post",
        body: payload,
      }),
    }),
    UpdateActiveTab: builder.mutation({
      query: () => ({
        url: "/v1/users/update_final_access",
        method: "PATCH",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    GoogleLoginProcess: builder.mutation({
      query: (jwt_token) => ({
        url: `/v1/users/registration//signup_with_google?jwt_token=${jwt_token}`,
        method: "Post",
      }),
    }),
  }),
});

export const { useLoginUserMutation } = Auth;
export const { useRegisterUSerMutation } = Auth;
export const { useForgotPasswordMutation } = Auth;
export const { useResetPasswordMutation } = Auth;
export const { useGetUserProfileQuery } = Auth;
export const { useUpdateUserMutation } = Auth;
export const { useGetUserDetailsQuery } = Auth;
export const { useUpdateUserDetailsMutation } = Auth;
export const { useRegisterAuthenticationMutation } = Auth;
export const { useResendRegistreVerficationMutation } = Auth;
export const { useUpdateActiveTabMutation } = Auth;
export const { useGoogleLoginProcessMutation } = Auth;
