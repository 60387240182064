import React, { useContext, useEffect, useState } from "react";
import AIDraft from "../modals/AIDraft";
import AIDraftProgress from "../modals/AIDraftProgress";
import AIDraftSuccess from "../modals/AIDraftSuccess";
import InnerStep1 from "./step2InnerSteps/InnerStep1";
import InnerStep2 from "./step2InnerSteps/InnerStep2";
import InnerStep3 from "./step2InnerSteps/InnerStep3";
import InnerStep4 from "./step2InnerSteps/InnerStep4";
import InnerStep5 from "./step2InnerSteps/InnerStep5";
import Data from "../../JSON/data.json";
import { ContextData } from "../../Store/Context";

const Step2 = ({
  currentStep,
  setCurrentStep,
  subStep,
  setSubStep,
  userDetail,
  setUserDetails,
  setUserStep,
}) => {
  const [openStep, setOpenStep] = useState(subStep || 1);
  const completedSteps = new Set();
  const [aiBaseText, setAiBaseText] = useState("");
  const [openAIModal, setOpenAIModal] = useState(false);
  const [openAIModalInProgress, setOpenAIModalInProgress] = useState(false);
  const [openAIModalSucess, setOpenAIModalSucess] = useState(false);
  const [userInput, setUserInput] = useState("");
  const { aiCall, setAiCall } = useContext(ContextData);
  const [selectedType, setSelectedType] = useState(1); // 1 => project details, 2 => achievements details

  useEffect(() => {
    if (openStep === 1) {
      setAiCall("editJobsummray");
    }
    else if (openStep === 5) {
      setAiCall("addProjecthistory");
    }
    else{
      setAiCall("");
    }
  }, [openStep]);
  
  // console.log("Ai Call:- ", aiCall);
  // console.log("Select Type:- ", selectedType);
  // console.log("openStep:- ", openStep);

  const handleStepClick = (step) => {
    switch (step) {
      case 1:
        setAiCall("editJobsummray");
        break;
      case 2:
        setAiCall("");
        break;
      case 3:
        setAiCall("");
        break;
      case 4:
        setAiCall("");
        break;
      case 5:
        setAiCall("addProjecthistory");
        break;

      default:
        break;
    }
    setOpenStep(openStep === step ? null : step);
  };

  const renderFormComponent = (step) => {
    switch (step) {
      case 1:
        return (
          <InnerStep1
            setOpenAIModal={setOpenAIModal}
            openStep={openStep}
            setOpenStep={setOpenStep}
            setCurrentStep={setCurrentStep}
            userDetail={userDetail}
            setUserDetails={setUserDetails}
            setSubStep={setSubStep}
          />
        );
      case 2:
        return (
          <InnerStep2
            openStep={openStep}
            setOpenStep={setOpenStep}
            setCurrentStep={setCurrentStep}
            userDetail={userDetail}
            setUserDetails={setUserDetails}
            setSubStep={setSubStep}
          />
        );
      case 3:
        return (
          <InnerStep3
            openStep={openStep}
            setOpenStep={setOpenStep}
            setCurrentStep={setCurrentStep}
            userDetail={userDetail}
            setUserDetails={setUserDetails}
            setSubStep={setSubStep}
          />
        );
      case 4:
        return (
          <InnerStep4
            openStep={openStep}
            setOpenStep={setOpenStep}
            setCurrentStep={setCurrentStep}
            userDetail={userDetail}
            setUserDetails={setUserDetails}
            setSubStep={setSubStep}
          />
        );
      case 5:
        return (
          <InnerStep5
            openStep={openStep}
            setOpenAIModal={setOpenAIModal}
            setOpenStep={setOpenStep}
            setCurrentStep={setCurrentStep}
            userDetail={userDetail}
            setUserDetails={setUserDetails}
            setSelectedType={setSelectedType}
            setUserStep={setUserStep}
            setSubStep={setSubStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full">
        {[1, 2, 3, 4, 5].map((step) => (
          <div key={step} className="mt-6 px-6 py-4 bg-white">
            <div
              className={`flex gap-4 items-center ${
                step <= subStep && "cursor-pointer"
              }`}
              onClick={() => step <= subStep && handleStepClick(step)}
            >
              <div
                className={`h-10 w-10 flex justify-center items-center rounded-full text-white transition-colors font-bold ${
                  completedSteps.has(step) ||
                  openStep === step ||
                  subStep >= step
                    ? "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                    : "bg-[#CED0D8]"
                }`}
              >
                {step}
              </div>
              <p className="text-[#212121] font-bold">
                {step === 1 && "直近の職務要約を入力しましょう"}
                {step === 2 && "得意分野を選択しましょう"}
                {step === 3 && "活かせるスキル・資格を入力しましょう"}
                {step === 4 && "職務経歴を入力しましょう"}
                {step === 5 && "プロジェクト経歴を入力しましょう"}
              </p>
            </div>
            {openStep === step && (
              <div
                className={`mt-2 transition-all duration-300 ${
                  openStep === step ? "opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                {renderFormComponent(step)}
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        className={`w-[300px] h-[40px] ${
          subStep !== 6
            ? "bg-gray-500"
            : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
        } text-white flex gap-2 justify-center items-center rounded-[4px] mt-[64px]`}
        type="submit"
        disabled={subStep !== 6}
        onClick={() => {
          setCurrentStep(3);
          setOpenStep(5);
        }}
      >
        <span>次へ</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.64598 1.64689C4.69242 1.60033 4.7476 1.56339 4.80834 1.53818C4.86909 1.51297 4.93421 1.5 4.99998 1.5C5.06575 1.5 5.13087 1.51297 5.19161 1.53818C5.25236 1.56339 5.30753 1.60033 5.35398 1.64689L11.354 7.64689C11.4005 7.69334 11.4375 7.74852 11.4627 7.80926C11.4879 7.87001 11.5009 7.93513 11.5009 8.00089C11.5009 8.06666 11.4879 8.13178 11.4627 8.19253C11.4375 8.25327 11.4005 8.30845 11.354 8.35489L5.35398 14.3549C5.26009 14.4488 5.13275 14.5015 4.99998 14.5015C4.8672 14.5015 4.73986 14.4488 4.64598 14.3549C4.55209 14.261 4.49935 14.1337 4.49935 14.0009C4.49935 13.8681 4.55209 13.7408 4.64598 13.6469L10.293 8.00089L4.64598 2.35489C4.59941 2.30845 4.56247 2.25327 4.53727 2.19253C4.51206 2.13178 4.49908 2.06666 4.49908 2.00089C4.49908 1.93513 4.51206 1.87001 4.53727 1.80926C4.56247 1.74852 4.59941 1.69334 4.64598 1.64689V1.64689Z"
            fill="white"
          />
        </svg>
      </button>
      {openAIModal && (
        <AIDraft
          setOpenAIModal={setOpenAIModal}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
          setUserInput={setUserInput}
          setAiBaseText={setAiBaseText}
          setOpenAIModalSucess={setOpenAIModalSucess}
          aiCall={aiCall}
          header={
            aiCall === "editJobsummray"
              ? Data.ai_message_workHistory
              : selectedType === 1
              ? Data.ai_message_project
              : Data.ai_message_achievements
          }
          sampleInput={
            aiCall === "editJobsummray"
              ? Data.ai_sample_header
              : selectedType === 1
              ? Data.ai_sample_header_project
              : Data.ai_sample_header
          }
          sampleInputData={
            aiCall === "editJobsummray"
              ? Data.sample_text_ai_workHistory
              : selectedType === 1
              ? Data.sample_text_ai_project
              : Data.sample_text_ai_achievements
          }
          selectedType={selectedType}
        />
      )}
      {openAIModalInProgress && (
        <AIDraftProgress
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
        />
      )}
      {openAIModalSucess && (
        <AIDraftSuccess
          setOpenAIModalSucess={setOpenAIModalSucess}
          generatedText={aiBaseText}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          setOpenAIModal={setOpenAIModal}
          setUserInput={setUserInput}
          aiCall={aiCall}
          userInput={userInput}
        />
      )}
    </div>
  );
};

export default Step2;
