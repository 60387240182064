import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";
import { Formik } from "formik";

import CloseModalButton from "../buttons/CloseModalButton";
import { resetPasswordSchema } from "../validation/FormikValidation";
import { useResetPasswordMutation } from "../../Store/slice/AuthSlice";
import Loader from "../buttons/Loader";
import PassHideShow from "../buttons/PassHideShow";
import { scrollToErrors } from "../comman/ScrollToError";

function ResetPassword({ setOpenResetPassword }) {
  const [resetPassword, { error, isLoading }] = useResetPasswordMutation();
  const modalRef = useRef(null);
  const [currentPass, setCurrentPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenResetPassword(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenResetPassword]);

  return (
    <div className="overlay">
      <div
        id="resetPassword-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div className="bg-[#fff] w-[420px] lg:w-[500px]" ref={modalRef}>
            <div className="border-b border-[#CED0D8] mb-[16px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  パスワードを再設定する
                </h3>
                <CloseModalButton onClick={() => setOpenResetPassword(false)} />
              </div>
            </div>
            <div className="">
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={resetPasswordSchema}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { setErrors, resetForm }) => {
                  const userPasswords = {
                    current_password: values.oldPassword,
                    password: values.newPassword,
                    password_confirmation: values.confirmPassword,
                  };
                  const response = await resetPassword(userPasswords);

                  if (response?.error) {
                    let filedErr = {};
                    response?.error?.data?.errors?.forEach((element) => {
                      filedErr[element.field] = element.detail;
                    });
                    setErrors(filedErr);
                  }

                  if (response?.data?.success === true) {
                    toast.success(
                      response?.data?.message ||
                        "パスワードが正常に更新されました。"
                    );
                    setOpenResetPassword(false);
                    resetForm();
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="h-[450px] xl2:h-[394px] px-[22px] overflow-y-auto">
                      <div>
                        <div>
                          <div className="font-medium text-[14px] leading-[22px] text-[#212121] relative">
                            <div className="">
                              <label
                                htmlFor="oldPassword"
                                className="my-2 block"
                              >
                                以前のパスワード
                              </label>
                            </div>
                            <input
                              name="oldPassword"
                              type={`${currentPass ? "text" : "password"}`}
                              placeholder="以前のパスワード"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.oldPassword}
                              className="w-full h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-[#FF6F00]"
                            />
                            <span
                              className="absolute top-[60%] right-[1%]"
                              onClick={() => setCurrentPass(!currentPass)}
                            >
                              <PassHideShow ShowHide={currentPass} />
                            </span>
                          </div>
                          {errors.oldPassword && touched.oldPassword && (
                            <p className="text-red-600 text-[12px]">
                              *{errors.oldPassword}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] relative">
                            <div className="">
                              <label
                                htmlFor="newPassword"
                                className="block my-2"
                              >
                                新しいパスワード
                              </label>
                            </div>
                            <input
                              name="newPassword"
                              type={`${newPass ? "text" : "password"}`}
                              placeholder="新しいパスワード"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.newPassword}
                              className="w-full h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-[#FF6F00]"
                            />
                            <span
                              className="absolute top-[60%] right-[1%]"
                              onClick={() => setNewPass(!newPass)}
                            >
                              <PassHideShow ShowHide={newPass} />
                            </span>
                          </div>
                          {errors.newPassword && touched.newPassword && (
                            <p className="text-red-600 text-[12px]">
                              *{errors.newPassword}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] relative">
                            <div className="">
                              <label
                                htmlFor="confirmPassword"
                                className="block my-2"
                              >
                                新しいパスワードを確認
                              </label>
                            </div>
                            <input
                              name="confirmPassword"
                              type={`${confirmPass ? "text" : "password"}`}
                              placeholder="新しいパスワードを確認"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirmPassword}
                              className="w-full h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-[#FF6F00]"
                            />
                            <span
                              className="absolute top-[60%] right-[1%]"
                              onClick={() => setConfirmPass(!confirmPass)}
                            >
                              <PassHideShow ShowHide={confirmPass} />
                            </span>
                          </div>
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <p className="text-red-600 text-[12px]">
                                *{errors.confirmPassword}
                              </p>
                            )}
                        </div>
                        <div className="mt-[40px] mb-[24px]">
                          <div className="flex gap-[10px] items-center">
                            <button
                              type="button"
                              className="w-[99px] h-[40px] py-[9px] px-[15px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                              onClick={() => setOpenResetPassword(false)}
                            >
                              キャンセル
                            </button>
                            <button
                              disabled={isLoading}
                              type="submit"
                              className={`w-[265px] lg:w-[358px] h-[40px] ${
                                isLoading
                                  ? "bg-neutral-500"
                                  : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                              } rounded-[4px] text-xs font-bold text-[#fff]`}
                            >
                              {isLoading ? (
                                <div className="flex justify-center">
                                  <Loader />
                                </div>
                              ) : (
                                "保存する"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
