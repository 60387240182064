import { Formik } from "formik";
import { RegistrationStep1Schema } from "../validation/FormikValidation";
import { scrollToErrors } from "../comman/ScrollToError";
import { affiliation_option, region } from "../../Components/comman/Options";
import Data from "../../JSON/data.json";
import DatePicker from "react-datepicker";
import { useUpdateUserMutation } from "../../Store/slice/AuthSlice";
import { toast } from "react-toastify";
import Loader from "../buttons/Loader";

const Step1 = ({
  currentStep,
  setCurrentStep,
  userProfile,
  setUserProfile,
  setUserStep,
}) => {
  // const [startDate, setStartDate] = useState(new Date());
  const [registerUserDetail, { error, isLoading }] = useUpdateUserMutation();

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  return (
    <div className="w-full flex flex-col items-center">
      <Formik
        initialValues={{
          first_name: userProfile?.first_name || "",
          last_name: userProfile?.last_name || "",
          romanized_first_name: userProfile?.romanized_first_name || "",
          romanized_last_name: userProfile?.romanized_last_name || "",
          dob: userProfile?.dob || "",
          affiliation: userProfile?.affiliation || "",
          region: userProfile?.region || "",
          steps_completed: "user_infomation",
        }}
        validationSchema={RegistrationStep1Schema}
        innerRef={(ref) => {
          if (ref?.errors && ref?.isSubmitting === true) {
            scrollToErrors(ref?.errors);
          }
        }}
        onSubmit={async (values, { setErrors }) => {
          const response = await registerUserDetail(values);

          if (response?.error) {
            let filedErr = {};
            response?.error?.data?.errors?.forEach((element) => {
              filedErr[element.field] = element.detail;
            });
            setErrors(filedErr);
            document.getElementsByName(Object.keys(filedErr))[0].focus();
          }

          if (response?.data?.success === true) {
            setUserProfile(response?.data?.data?.user);
            toast.success(response?.data?.message);
            setCurrentStep(2);
            setUserStep(2);
          }
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col justify-center items-center"
          >
            <div className="w-full py-10 bg-white">
              <h1 className="text-base font-bold mx-auto w-fit mb-4 test-[#212121]">
                ユーザー情報を入力しましょう
              </h1>
              <div className="w-[50%] mx-auto">
                <div className="font-medium text-[14px] leading-[22px] text-[#212121] flex flex-col gap-[10px]">
                  <label htmlFor="name">氏名</label>
                  <input
                    type="text"
                    placeholder="姓"
                    name="first_name"
                    value={values.first_name}
                    autoFocus={currentStep === 1 && !errors}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full h-[48px] font-normal py-[10px] px-[16px] border border-[#CED0D8] rounded-[2px] placeholder:text-[#CED0D8] focus:outline-none"
                  />
                  {errors.first_name && touched.first_name && (
                    <p className="text-red-600 text-[12px]">
                      *{errors.first_name}
                    </p>
                  )}
                  <input
                    type="text"
                    placeholder="名"
                    name="last_name"
                    value={values.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full h-[48px] font-normal py-[10px] px-[16px] border border-[#CED0D8] rounded-[2px] placeholder:text-[#CED0D8] focus:outline-none"
                  />
                  {errors.last_name && touched.last_name && (
                    <p className="text-red-600 text-[12px]">
                      *{errors.last_name}
                    </p>
                  )}
                </div>
                <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] flex flex-col gap-[10px]">
                  <label htmlFor="name">氏名(ローマ字)</label>
                  <input
                    type="text"
                    placeholder="LastName"
                    name="romanized_last_name"
                    value={values?.romanized_last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full h-[48px] font-normal py-[10px] px-[16px] border border-[#CED0D8] rounded-[2px] placeholder:text-[#CED0D8] focus:outline-none"
                  />
                  {errors.romanized_last_name &&
                    touched.romanized_last_name && (
                      <p className="text-red-600 text-[12px]">
                        *{errors.romanized_last_name}
                      </p>
                    )}
                  <input
                    type="text"
                    placeholder="FirstName"
                    name="romanized_first_name"
                    value={values.romanized_first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full h-[48px] font-normal py-[10px] px-[16px] border border-[#CED0D8] rounded-[2px] placeholder:text-[#CED0D8] focus:outline-none"
                  />
                  {errors.romanized_first_name &&
                    touched.romanized_first_name && (
                      <p className="text-red-600 text-[12px]">
                        *{errors.romanized_first_name}
                      </p>
                    )}
                </div>
                <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] flex flex-col gap-[10px]">
                  <label htmlFor="name">生年月日</label>
                  <DatePicker
                    autoComplete="off"
                    name="dob"
                    selected={values?.dob}
                    dateFormat={"YYYY/MM/dd"}
                    maxDate={new Date()}
                    showYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      const dateString = date;
                      const dates = new Date(dateString);
                      const formattedDate = dates?.toISOString().split("T")[0];
                      setFieldValue("dob", formattedDate);
                    }}
                    className="w-full h-[48px] font-normal py-[10px] px-[16px] border border-[#CED0D8] rounded-[2px] placeholder:text-[#CED0D8] focus:outline-none"
                    placeholderText={Data?.date_of_birth}
                  />
                  {errors.dob && touched.dob && (
                    <p className="text-red-600 text-[12px]">*{errors.dob}</p>
                  )}
                </div>
                <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] flex flex-col gap-[10px]">
                  <label htmlFor="name">所属</label>
                  <select
                    name="affiliation"
                    value={values.affiliation}
                    placeholder="所属を入力してください。"
                    onChange={handleChange}
                    className="w-full h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px] focus:outline-none"
                    onBlur={handleBlur}
                    style={{ display: "block" }}
                  >
                    <option value="" label="所属を入力してください。">
                      所属を入力してください。
                    </option>
                    {affiliation_option?.map((option, index) => {
                      return (
                        <option key={index} value={option?.value}>
                          {option?.label}
                        </option>
                      );
                    })}
                  </select>
                  {errors.affiliation && touched.affiliation && (
                    <p className="text-red-600 text-[12px]">
                      *{errors.affiliation}
                    </p>
                  )}
                </div>
                <div className="ffont-medium text-[14px] leading-[22px] text-[#212121] mt-[16px] flex flex-col gap-[10px]">
                  <label htmlFor="employment_type">地域</label>
                  <div>
                    <select
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      className="w-full h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px] focus:outline-none"
                      onBlur={handleBlur}
                      style={{ display: "block" }}
                    >
                      <option value="" label="地域を選択してください。">
                        地域を選択してください。
                      </option>
                      {region.map((ele, index) => (
                        <option key={index} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                    {errors.region && touched.region && (
                      <p className="text-red-600 text-[12px]">
                        *{errors.region}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 flex gap-10 items-center justify-center w-fit">
              <button
                type="submit"
                disabled={
                  Object.keys(errors)?.length ||
                  isLoading ||
                  values.first_name === "" ||
                  values.last_name === "" ||
                  values.romanized_first_name === "" ||
                  values.romanized_last_name === "" ||
                  values.dob === "" ||
                  values.affiliation === "" ||
                  values.region === ""
                }
                className={`w-[300px] h-[40px] text-[#fff] flex gap-2 justify-center items-center rounded-[4px]
                ${
                  Object.keys(errors)?.length ||
                  isLoading ||
                  values.first_name === "" ||
                  values.last_name === "" ||
                  values.romanized_first_name === "" ||
                  values.romanized_last_name === "" ||
                  values.dob === "" ||
                  values.affiliation === "" ||
                  values.region === ""
                    ? "bg-[#CED0D8]"
                    : "bg-[#0956FF]"
                } `}
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  <span>次へ</span>
                )}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.64598 1.64689C4.69242 1.60033 4.7476 1.56339 4.80834 1.53818C4.86909 1.51297 4.93421 1.5 4.99998 1.5C5.06575 1.5 5.13087 1.51297 5.19161 1.53818C5.25236 1.56339 5.30753 1.60033 5.35398 1.64689L11.354 7.64689C11.4005 7.69334 11.4375 7.74852 11.4627 7.80926C11.4879 7.87001 11.5009 7.93513 11.5009 8.00089C11.5009 8.06666 11.4879 8.13178 11.4627 8.19253C11.4375 8.25327 11.4005 8.30845 11.354 8.35489L5.35398 14.3549C5.26009 14.4488 5.13275 14.5015 4.99998 14.5015C4.8672 14.5015 4.73986 14.4488 4.64598 14.3549C4.55209 14.261 4.49935 14.1337 4.49935 14.0009C4.49935 13.8681 4.55209 13.7408 4.64598 13.6469L10.293 8.00089L4.64598 2.35489C4.59941 2.30845 4.56247 2.25327 4.53727 2.19253C4.51206 2.13178 4.49908 2.06666 4.49908 2.00089C4.49908 1.93513 4.51206 1.87001 4.53727 1.80926C4.56247 1.74852 4.59941 1.69334 4.64598 1.64689V1.64689Z"
                    fill="white"
                  />
                </svg>
              </button>
              {/* <button
                className="flex gap-2 justify-center items-center text-[#0956FF] text-xs font-bold"
                onClick={() => setCurrentStep(2)}
              >
                <span>スキップ</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.64598 1.64689C4.69242 1.60033 4.7476 1.56339 4.80834 1.53818C4.86909 1.51297 4.93421 1.5 4.99998 1.5C5.06575 1.5 5.13087 1.51297 5.19161 1.53818C5.25236 1.56339 5.30753 1.60033 5.35398 1.64689L11.354 7.64689C11.4005 7.69334 11.4375 7.74852 11.4627 7.80926C11.4879 7.87001 11.5009 7.93513 11.5009 8.00089C11.5009 8.06666 11.4879 8.13178 11.4627 8.19253C11.4375 8.25327 11.4005 8.30845 11.354 8.35489L5.35398 14.3549C5.26009 14.4488 5.13275 14.5015 4.99998 14.5015C4.8672 14.5015 4.73986 14.4488 4.64598 14.3549C4.55209 14.261 4.49935 14.1337 4.49935 14.0009C4.49935 13.8681 4.55209 13.7408 4.64598 13.6469L10.293 8.00089L4.64598 2.35489C4.59941 2.30845 4.56247 2.25327 4.53727 2.19253C4.51206 2.13178 4.49908 2.06666 4.49908 2.00089C4.49908 1.93513 4.51206 1.87001 4.53727 1.80926C4.56247 1.74852 4.59941 1.69334 4.64598 1.64689V1.64689Z"
                    fill="#0956FF"
                  />
                </svg>
              </button> */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Step1;
