import React from "react";
import CloseModalButton from "../buttons/CloseModalButton";
import Loader from "../buttons/Loader";

function Deletemodal({
  heading,
  text,
  yes,
  no,
  setModalOpen,
  handleDelete,
  refModal,
  isLoading,
}) {
  return (
    <div className="overlay">
      <div
        id="delete-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div className="bg-[#fff] w-[516px] lg:w-[632px]" ref={refModal}>
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  {heading}
                </h3>
                <CloseModalButton onClick={() => setModalOpen(false)} />
              </div>
            </div>
            <div className="p-4 md:p-5 text-center mx-[16px] pt-0">
              <h3 className="mb-10 text-sm font-normal text-black">{text}</h3>
              <button
                data-modal-hide="delete-modal"
                type="button"
                onClick={() => handleDelete()}
                className="text-white bg-[#F54141] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-lg text-[12px] font-bold inline-flex items-center px-[86px] py-2.5 text-center"
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <Loader />
                  </div>
                ) : (
                  yes
                )}
              </button>
              <button
                data-modal-hide="delete-modal"
                type="button"
                onClick={() => setModalOpen(false)}
                className="py-2.5 px-[9px] ms-3 text-[12px] font-medium text-black focus:outline-none rounded-lg focus:z-10 border border-[#CED0D8] focus:ring-4 focus:ring-gray-100 outline-none"
              >
                {no}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deletemodal;
