import React from "react";
import Data from "../../JSON/data.json";
import CloseModalButton from "../buttons/CloseModalButton";
// import { scrollToErrors } from "../comman/ScrollToError";
// import { Formik } from "formik";
import AITextLoader from "../Loader/AITextLoader";
// import { useLocation } from "react-router-dom";

const AIDraftProgress = ({ refModal, setOpenAIModalInProgress, userInput }) => {
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   const timeoutId = setTimeout(()=>{
  //     setOpenAIModalInProgress(false);
  //     setOpenAIModalSucess(true)
  //   },6000)

  //   return () => clearTimeout(timeoutId);
  // },[])

  return (
    <div className="overlay">
      <div
        id=""
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className=" bg-[#fff] w-[500px] overflow-y-auto overflow-x-hidden"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-base tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  {Data?.ai_text}
                </h3>
                <CloseModalButton
                  onClick={() => setOpenAIModalInProgress(false)}
                />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-3">
              <AITextLoader />
              <h3 className={`font-bold text-md mt-4 text-[#0956FF]`}>
                {Data.ai_text_inprogress}
              </h3>
              <p className="font-normal text-xs p-2">30秒ほどお待ちください</p>
            </div>
            <div className="bg-[#F6F6F6] m-5 p-3 whitespace-pre-line">
              <ul className="text-sm font-normal leading-5 p-4 list-none">
                {userInput ? (
                  <li>{userInput}</li>
                ) : (
                  Data.sample_text_ai.map((text, index) => (
                    <li key={index}>{text}</li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AIDraftProgress;
