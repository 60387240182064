import { useContext, useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../Components/comman/Header";
import Footer from "../Components/comman/Footer";
import Sidebar from "../Components/comman/Sidebar";
import PageLoader from "../Components/Loader/PageLoader";
import {
  Auth,
  useGetUserDetailsQuery,
  // useGetUserProfileQuery,
} from "../Store/slice/AuthSlice";
import { ContextData } from "../Store/Context";
import ProfileHeader from "../Components/profile/ProfileHeader";

export default function MainLayout() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const token = localStorage.getItem("token");
  const [user, setUser] = useState();
  // const { data, error, isLoading, isError } = useGetUserProfileQuery();
  const [trigger, { isLoading }] = Auth.endpoints.GetUserProfile.useLazyQuery();
  const { data: details, isLoading: loader } = useGetUserDetailsQuery();
  const { tokenForLocal, setUserProfile, setUserDetails } =
    useContext(ContextData);

  useEffect(() => {
    // if (!token) {
    //   navigate("/");
    //   return;
    // }
    // if (token && !isError) {
    //   setUserProfile(data?.data?.user);
    //   return setUser(data?.data?.user);
    // }
    // if (token !== tokenForLocal && isError) {
    //   toast.error(error?.data?.message);
    //   localStorage.removeItem("token");
    //   navigate("/");
    // }
    // }, [data, token, isError, tokenForLocal, navigate, setUserProfile, error?.data?.message]);

    const getProfile = async () => {
      if (!token) {
        navigate("/");
        return;
      } else {
        const { data, error, isError } = await trigger();
        if (isError && token !== tokenForLocal) {
          toast.error(error?.data?.message);
          localStorage.removeItem("token");
          navigate("/");
        }
        if (!isError && data) {
          switch (data?.data?.user?.steps_completed) {
            case "initial_step": navigate("/registration-process", { state: { step: 0 } }); break;
            case "user_infomation": navigate("/registration-process", { state: { step: 2, innerStep: 1 } }); break;
            case "job_summary": navigate("/registration-process", { state: { step: 2, innerStep: 2 } }); break;
            case "specialty": navigate("/registration-process", { state: { step: 2, innerStep: 3 } }); break;
            case "skills": navigate("/registration-process", { state: { step: 2, innerStep: 4 } }); break;
            case "work_history": navigate("/registration-process", { state: { step: 2, innerStep: 5 } }); break;
            case "project_history": navigate("/registration-process", { state: { step: 3, innerStep: 6 } }); break;
            case "output": navigate("/registration-process", { state: { step: 4 } }); break;
            case "completion": navigate("/profile"); break;

            default:
              break;
          }

          setUserProfile(data?.data?.user);
          setUser(data?.data?.user);
        }
      }
    };
    getProfile();
    // eslint-disable-next-line
  }, [token, tokenForLocal]);

  useEffect(() => {
    setUserDetails({ isLoading: loader, details: details?.data?.details });
  }, [details, loader, setUserDetails]);

  return (
    <div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div
          className={`${pathname === "/registration-process"
            ? "bg-[#E6EEFF]"
            : "bg-[#F6F6F6]"
            }`}
        >
          <div className="sticky top-0 bg-[#fff] w-full h-[64px] z-[30]">
            <Header userProfile={user} />
          </div>
          <div>
            {pathname !== "/output-resume" &&
              pathname !== "/registration-process" && (
                <ProfileHeader
                // data={data}
                // error={error}
                // isLoading={isLoading}
                // isError={isError}
                // user={user}
                // setUser={setUser}
                />
              )}
            <div className="mx-[12px] md:mx-[18px] xl:mx-[26px] flex gap-[12px] md:gap-[18px] xl:gap-[26px]">
              {pathname !== "/output-resume" &&
                pathname !== "/registration-process" && (
                  <div>
                    <Sidebar />
                  </div>
                )}
              <main className="w-full h-full">
                <Outlet />
              </main>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
