import { Outlet } from "react-router-dom";
// import authsideimg from "../Assets/Images/authSideImg.png";
import authsideimg from "../Assets/Images/auth-img1.png";

function AuthLayout() {
  const year = new Date().getFullYear();
  return (
    <div className="auth h-screen w-screen flex items-center justify-center">
      <div>
        <div className="login w-auto md:w-[650px] lg:w-[998px] h-auto lg:h-[654px] flex z-10 bg-white">
          <div className="w-full h-[inherit] lg:h-[654px] flex items-center">
            <img
              src={authsideimg}
              alt="sideimg"
              className="h-fit w-full object-fill lg:object-cover"
            />
          </div>
          <Outlet />
        </div>
        <div className="mt-[20px] text-[14px] leading-[22px] font-normal text-white flex justify-center">
          {`@${year} 株式会社Plus Synergy All Right Reserved.`}
        </div>
      </div>
    </div>
  );
}
export default AuthLayout;
