import { useEffect, useState } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Data from "../../JSON/data.json";
import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";
import { RegisterSchema } from "../../Components/validation/FormikValidation";
import { useGoogleLoginProcessMutation, useRegisterUSerMutation } from "../../Store/slice/AuthSlice";
import { scrollToErrors } from "../../Components/comman/ScrollToError";
import Loader from "../../Components/buttons/Loader";
import PassHideShow from "../../Components/buttons/PassHideShow";
import { GoogleLogin } from "@react-oauth/google";


function SignUp() {
  const navigate = useNavigate();
  const dataLayer = window.dataLayer;
  const [getRegisterUser, { error, isLoading }] = useRegisterUSerMutation();
  const [pass, setPass] = useState(false)
  const [googleLoginProcess] = useGoogleLoginProcessMutation();
  const [termsCondition, setTermsCondition] = useState(false);

  error && toast.error(error?.data?.message || "何か問題が発生しました!");

  useEffect(() => {
    dataLayer?.push({
      event: 'reg_open',
    });
    // eslint-disable-next-line
  }, [])

  return (
    <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[30px] w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] md:w-[152px] h-[20px] md:h-[24px] flex justify-center" />
          <h1 className="leading-[150%] text-[12px] md:text-[16px] lg:text-[22px] font-bold mt-[14px] md:mt-[18px] lg:mt-[18px]">
            {Data?.register_text}
          </h1>
          <Link to="/" className="flex justify-center mt-[14px]">
            <p className="font-normal text-[16px] text-[#212121] leading-[22px]">
              {Data?.Already_have_an_account}
              <span className="text-[#FF6F00] underline"> こちら </span>
            </p>
          </Link>
        </div>
        <div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              terms_condition: false,
            }}
            validationSchema={RegisterSchema}
            innerRef={(ref) => {
              if (ref?.errors && ref?.isSubmitting === true) {
                scrollToErrors(ref?.errors);
              }
            }}
            onSubmit={async (values, { setErrors, resetForm }) => {
              const response = await getRegisterUser(values);

              if (response?.error) {
                let filedErr = {};
                response?.error?.data?.errors?.forEach((element) => {
                  filedErr[element.field] = element.detail;
                });
                setErrors(filedErr);
                document.getElementsByName(Object.keys(filedErr))[0].focus();
              }

              if (response?.data?.success === true) {
                toast.success(response?.data?.message);
                navigate("/under-review", { state: { email: values?.email } });
                resetForm();
              }
            }}
          >
            {({
              errors, touched, handleChange, handleBlur, values, handleSubmit, setFieldValue
            }) => (
              <form onSubmit={handleSubmit} className="mb-[20px] mt-[20px] overflow-hidden">
                <div className="md:h-[285px] lg:h-[430px] overflow-y-auto overflow-hidden">
                  <div>
                    <div className="mb-[20px]">
                      <label
                        htmlFor="email"
                        className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[150%] text-[#212121]"
                      >
                        {Data?.email_address}
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                        <input
                          name="email"
                          type="email"
                          placeholder={Data?.email_placeholder}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          className="custom-input w-full p-[12px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent" />
                        <div className="custom-border"></div>
                      </div>
                      {errors.email && touched.email && (
                        <p className="text-red-600 text-[12px]">
                          *{errors.email}
                        </p>
                      )}
                    </div>

                    <div>
                      <div>
                        <label
                          htmlFor="password"
                          className="text-[10px] xs:text-[12px] md:text-[14px] font-medium leading-[150%] text-[#212121]"
                        >
                          {Data?.password}
                          <span className="text-red-500">*</span>
                        </label>
                      </div>
                      <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                        <input
                          name="password"
                          type={pass ? "text" : "password"}
                          placeholder={Data?.Password_placeholder}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          className="border border-[#CED0D8] w-full p-[12px] pr-[45px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent" />
                        <div className="custom-border-pass"></div>
                        <span
                          className="absolute top-[33%] right-[5%] z-[99999]"
                          onClick={() => setPass(!pass)}
                        >
                          <PassHideShow ShowHide={pass} />
                        </span>
                      </div>
                      <p className="text-[10px] leading-[22px] mt-[5px] text-[#868686] font-medium">
                        ※半角英数字の組み合わせ (8文字以上15文字以下)
                      </p>
                      {errors.password && touched.password && (
                        <p className="text-red-600 text-[12px]">
                          *{errors.password}
                        </p>
                      )}
                    </div>

                    <div className="mt-[10px]">
                      <div className="cursor-pointer flex justify-center items-center">
                        <label>
                          <input
                            name="terms_condition"
                            type="checkbox"
                            className="h-[18px] w-[18px] cursor-pointer mt-[20px]"
                            onBlur={handleBlur}
                            onChange={(e) => { setFieldValue("terms_condition", e.target.checked); setTermsCondition(!termsCondition) }} />
                        </label>
                        <div className="flex justify-center mt-[14px] ml-[8px] font-normal text-[10px] lg:text-[12px] leading-[22px]">
                          <Link
                            to="https://torpid-apparel-d15.notion.site/ab85786367ed47958f98950a60a6b91f"
                            target="_blank"
                          >
                            <div className="text-[#FF6F00] underline">
                              利用規約
                            </div>
                          </Link>
                          ・{" "}
                          <Link
                            to="https://torpid-apparel-d15.notion.site/3e962470eabd48d5a2c9ccc7e773a809"
                            target="_blank"
                            className="underline text-[#FF6F00] "
                          >
                            プライバシーポリシー
                          </Link>
                          ・{" "}
                          <Link
                            to="https://torpid-apparel-d15.notion.site/27a28c163e7048a9930fc20d22d0bc10?pvs=25"
                            target="_blank"
                          >
                            <span className="text-[#FF6F00] underline">
                              外部送信ポリシー
                            </span>
                            <span className="text-[#FF6F00]">に同意する</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center mt-[10px]">
                    <div className="flex">
                      <button
                        type="submit"
                        disabled={Object.keys(errors)?.length ||
                          !values?.terms_condition ||
                          isLoading}
                        className={`w-[150px] sm:w-[250px] md:w-[270px] lg:w-[387px] h-[60px] leading-6 font-medium ${Object.keys(errors)?.length
                          ? "bg-neutral-500"
                          : values?.terms_condition
                            ? !isLoading
                              ? "bg-custom-gradient"
                              : "bg-neutral-500"
                            : "bg-neutral-500"}  text-[#fff]`}
                      >
                        {isLoading ? (
                          <div className="flex justify-center">
                            <Loader />
                          </div>
                        ) : (
                          "会員登録する"
                        )}
                      </button>
                      <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                    </div>
                  </div>
                  <div className="w-full mt-[10px]">
                    {
                      !termsCondition ? (
                        <div>
                          <button disabled className="py-2 px-2 w-[401px] flex items-center gap-3 bg-white border border-[#DADCE0] justify-center rounded-[4px]">
                            <svg viewBox="0 0 48 48" className="LgbsSe-Bz112c w-4 h-4"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                            <span className="text-[12px]">Sign up with Google</span>
                          </button>
                          <p className="text-[12px] text-center">チェックボックスを押すとGoogleアカウントで登録ができます</p>
                        </div>
                      ) : (
                        <GoogleLogin
                          width={"405px"}
                          size="large"
                          onSuccess={async (credentialResponse) => {
                            const response = await googleLoginProcess(
                              credentialResponse.credential
                            );
                            if (response?.data) {
                              localStorage.setItem("token", response?.data?.jwt?.token);
                              toast.success("ログインに成功しました。");
                              navigate("/profile");
                            }
                            if (response?.error) {
                              toast.error(response?.error?.error);
                            }
                          }}
                          onError={() => {
                            toast.error("ログインに失敗しました");
                          }}
                        />
                      )
                    }
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
export default SignUp;
