import CloseIcon from "../../Assets/icons/closeIcon.svg";

function CloseModalButton({ onClick }) {
  return (
    <button
      type="button"
      data-modal-hide="default-modal"
      className=" absolute top-0 right-[16px]"
      onClick={onClick}
    >
      <img src={CloseIcon} alt="CloseIcon" />
    </button>
  );
}
export default CloseModalButton;
