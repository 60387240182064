import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const WorkHistory = createApi({
  reducerPath: "WorkHistory",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  endpoints: (builder) => ({
    GetWorkHistory: builder.mutation({
      query: (token) => {
        return {
          url: "/v1/work_histories",
          method: "Get",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),
    AddWorkHistory: builder.mutation({
      query: (payload) => {
        return {
          url: "/v1/work_histories",
          method: "Post",
          body: payload,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),
    GetWorkHistoryById: builder.mutation({
      query: (user_id) => {
        return {
          url: `/v1/work_histories/${user_id}`,
          method: "Get",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),
    UpdateWorkHistory: builder.mutation({
      query: (updatedData) => {
        return {
          url: `/v1/work_histories/${updatedData?.user_id}`,
          method: "Put",
          body: updatedData,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),
    DeleteWorkHistory: builder.mutation({
      query: (user_id) => {
        return {
          url: `/v1/work_histories/${user_id}`,
          method: "Delete",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),
  }),
});

export const { useGetWorkHistoryMutation } = WorkHistory;
export const { useAddWorkHistoryMutation } = WorkHistory;
export const { useUpdateWorkHistoryMutation } = WorkHistory;
export const { useGetWorkHistoryByIdMutation } = WorkHistory;
export const { useDeleteWorkHistoryMutation } = WorkHistory;
